/* * {
    border: 1px solid black;
} */

body {
    overflow-y: scroll;
}

body::-webkit-scrollbar {
    display: none;
}

p, a, h1, h2, h3, h4, h5, h6, label, small, input, button {
    font-family: 'custom_font';
    font-weight: normal;
    font-style: normal;
}

p {
    color: black!important;
}

h3 {
    color: rgb(15, 117, 188);
}

a {
    transition-duration: .5s;
    text-decoration: none;
    color: black!important;
}

a:hover {
    color: rgb(15, 117, 188)!important;
    transition-duration: .5s;
}

/**/


/* Navigation Styling */
/* .main_navbar {
    border-bottom: 1px solid rgb(191, 30, 45);
} */

.nav_hr {
    color: rgb(191, 30, 45);
    margin: 0 3%;
    height: 2px!important;
}

.offset-left {
    margin-right: 3%;
}

.offset-right {
    margin-left: 3%;
}

/* Home Page */
.secondary_wrapper {
    margin-top: 4%;
    display: flex;
}

.left, .right {
    width: 48%;
    text-align: center;
}

.inner_right {
    margin: 3% 10% 0 10%;
    line-height: 2;
}

.thirdary_wrapper {
    margin-top: 2%;
}
.thirdary_wrapper .title {
    text-align: center;
}
.thirdary_wrapper .title p {
    font-size: 10px;
    color: rgb(191, 30, 45)!important;
}
.thirdary_wrapper .title2 p {
    text-align: center;
    font-size: 12px;
}
.title2 {
    margin-top: 2%;
}
.inner_1, .inner_2 {
    display: flex;
    justify-content: space-evenly;
}
.card {
    border-radius: .5px;
    width: 18rem;
    height: fit-content;
    border-style: groove;
}
.blue {
    box-shadow: 5px 5px 5px 5px rgb(15, 117, 188);
}
.red {
    box-shadow: 5px 5px 5px 5px rgb(191, 30, 45);
}

/* Footer*/
.footer {
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;
    bottom: 0;
    left: 0;
    width: 100%;
}